import { FunctionComponent, useEffect, useState } from 'react';
import { useIntersectionObserver } from 'react-intersection-observer-hook';
import dynamic from 'next/dynamic';

import StyledComponent from './styles';
import { Props } from './types';

const SectionMap = dynamic(() => import('components/modules/public/layout/sections/Map'));

const PublicSectionMapVariantDefault: FunctionComponent<Props> = ({ ...props }) => {
    const [isCompVisible, setIsCompVisible] = useState<boolean>(false);
    const [ref, { entry }] = useIntersectionObserver();

    const isVisible = entry && entry.isIntersecting;
    const [forceRefreshComp, setForceRefreshComp] = useState<boolean>(false);

    useEffect(() => {
        if (isVisible && !isCompVisible) {
            setIsCompVisible(true);
        }
    }, [isVisible]);

    // Brakuje mi pomysłu na lepsze rozwiązanie, żeby robić refresh componentu, ma jakiś problem przy initial
    // byc możę coś związanego z dynamic componentem i działaniu na refach mapy
    useEffect(() => {
        if (isCompVisible) {
            setTimeout(() => {
                setForceRefreshComp(!forceRefreshComp);
            }, 500);
        }
    }, [isCompVisible]);

    console.log(props);

    return (
        <StyledComponent
            className="public-section-map-variant-default"
            id={props?.sectionSettings?.filters?.category || ''}
            ref={ref}
        >
            {isCompVisible && (
                <SectionMap
                    {...props}
                />
            )}
        </StyledComponent>
    );
};

export default PublicSectionMapVariantDefault;
